import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ArgonDashboard from './plugins/argon-dashboard'
import Multiselect from 'vue-multiselect'
import {ApiMixin} from "@/mixin/ApiMixin"
import VueSweetalert2 from 'vue-sweetalert2'
import Axios from 'axios'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueScrollTo  from 'vue-scrollto'
import VueFirestore from 'vue-firestore'
import firebase from "@firebase/app"

const configOptions = {
  apiKey: "AIzaSyAt005HFdMcIP688HIHc6LbfiNAzNMTdp8",
  authDomain: "samvatsari-registration.firebaseapp.com",
  databaseURL: "https://samvatsari-registration.firebaseio.com",
  projectId: "samvatsari-registration",
  storageBucket: "samvatsari-registration.appspot.com",
  messagingSenderId: "996350082010",
  appId: "1:996350082010:web:11a2e24a7f743e5e075ee3"
};

firebase.initializeApp(configOptions)

Vue.use(VueFirestore)

Vue.prototype.$http = Axios;

Vue.use(VueSweetalert2)

Vue.config.productionTip = false

Vue.component('multiselect', Multiselect)

Vue.mixin(ApiMixin)

Vue.use(VueScrollTo)

Vue.use(ArgonDashboard)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
