import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/BaseLayout'
Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/',
      component: DashboardLayout,
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import('./views/Landing.vue')
        }
      ]
    },
    // {
    //   path: '/samvatsari-registration',
    //   name:'Register',
    //   component: () => import('./views/Register.vue')
    // }
    ]
})
