<template>
		<footer class="footer">
			<div class="container">
				<div class="row">
					<div class="col-4 text-center py-3 border-right">
						<a href="https://www.facebook.com/OneJain.org" target="_new" class="link fb" data-toggle="tooltip" data-placement="top"
							title="Facebook">
							<i class="font-22 fab fa-facebook-f mr-2"></i>
							facebook
						</a>
					</div>
					<div class="col-4 text-center py-3 border-right">
						<a href="https://www.youtube.com/channel/UC4r6qzRi0EAfves7CqvObgg/featured" class="link youtube" target="_new" data-toggle="tooltip" data-placement="top"
							title="Youtube"><i class="font-22 fab fa-youtube mr-2"></i>
							youtube
						</a>
					</div>
					<div class="col-4 text-center py-3">
						<a href="https://t.me/onejain" class="link telegram" target="_new" data-toggle="tooltip" data-placement="top"
							title="Telegram"><i class="font-22 fab fa-telegram mr-2"></i>
							telegram
						</a>
					</div>
				</div>
			</div>
		</footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
