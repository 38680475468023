<template>
  <header class="topbar" :class="{ 'fixed-header': showNavbar }">
    <div class="container-fluid">
      <nav class="navbar top-navbar navbar-expand-lg navbar-dark">
        <div class="navbar-header">
          <!-- ============================================================== -->
          <!-- Logo -->
          <!-- ============================================================== -->
          <div class="navbar-brand">
            <a href="/">
              <b class="logo-icon">
                <img src="@/assets/images/logo.png" alt="homepage" class="light-logo">
              </b>
            </a>
          </div>
          <!-- ============================================================== -->
          <!-- End Logo -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Toggle which is visible on mobile only -->
          <!-- ============================================================== -->
          <a
            class="topbartoggler togglesidebar d-block lh-20 d-lg-none waves-effect waves-light"
            href="javascript:void(0)"
            @click="showMobileNav = !showMobileNav"
          >
            <i class="fas fa-bars text-dark"></i>
          </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse collapse" :class="{ 'show': showMobileNav }" id="nav-sidebar">
          <!-- ============================================================== -->
          <!-- toggle and nav items -->
          <!-- ============================================================== -->
          <ul class="navbar-nav ml-auto">
            <!-- <li class="nav-item">
              <a
                class="nav-link topbar-link"
                href="#"
                v-scroll-to="{ el:'#aalochana', offset:-71,  onDone: onDone }"
              >
                <span>Aalochana Book</span>
              </a>
            </li> -->
            <li class="nav-item">
              <a
                class="nav-link topbar-link"
                href="#"
                v-scroll-to="{ el:'#about', offset:-71,  onDone: onDone }"
              >
                <span>About One Jain</span>
              </a>
            </li>
            <!--<li class="nav-item">
              <a class="nav-link topbar-link" href="#paryushan-schedule">
                <span>Paryushan Schedule</span>
              </a>
            </li>-->

            <!--<li class="nav-item">
              <a class="nav-link topbar-link" href="#" v-scroll-to="{ el:'#samvatsari-registration', offset:-71, onDone: onDone }">
                <span>Samvatsari Pratikraman</span>
              </a>
            </li>-->
            <!-- <li class="nav-item">
              <a
                class="nav-link topbar-link"
                href="#"
                v-scroll-to="{ el:'#tapasvi-aaradhana', offset:-71, onDone: onDone }"
              >
                <span>Tapasvi Aaradhana</span>
              </a>
            </li> -->
            <li class="nav-item">
              <a
                class="nav-link topbar-link"
                href="#"
                v-scroll-to="{ el:'#sangh', offset:-71, onDone: onDone }"
              >
                <span>Sanghs</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link topbar-link"
                href="#"
                v-scroll-to="{ el:'#contact-us', offset:-71, onDone: onDone }"
              >
                <span>Contact us</span>
              </a>
            </li>
            <!--<li class="nav-item">
              <a class="nav-link topbar-link" href="#sangh">
                <span>Sangh</span>
              </a>
            </li>-->
            <!--<li class="nav-item">
              <a class="nav-link topbar-link" href="#register">
                <span>Register</span>
              </a>
            </li>-->
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      showMobileNav: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onDone() {
      this.showMobileNav = false;
    },
    onScroll() {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        this.showNavbar = true;
      } else {
        this.showNavbar = false;
      }
    }
  }
};
</script>
<style>
.navbar-collapse {
  position: initial !important;
}
</style>
